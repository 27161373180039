import React from 'react'
import {useStaticQuery, graphql} from 'gatsby'
import {ADVERTORIAL_BASE_URL} from '../constants'
import {formatAdvertorialGifs} from '../util/advertorialImages'
import Layout from '../components/layout'

import Advertorial from '../components/templates/sp-644-advertorial/advertorial'

const FinancialSearch = () => {
    const images = useStaticQuery(graphql`
        query AdvertorialFinancialSearch {
            hero: file(relativePath: {eq: "advertorial/finance/finance-hero.png"}) {
                childImageSharp {
                    fluid(maxWidth: 1000) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            allFile(filter: {base: {glob: "*finance*"}, extension: {eq: "gif"}}) {
                edges {
                    node {
                        publicURL
                    }
                }
            }
        }
    `)

    const seo = {
        title: `Don't Let Your Online Financial Activities Be Tracked | Startpage`,
        description: `Beware non-private search engines are saving and selling personal credit and financial search history. Learn how to protect your online privacy with Startpage.`,
        ogImagePath: `${ADVERTORIAL_BASE_URL}${images.hero.childImageSharp.fluid.src}`,
        ogUrl: `${ADVERTORIAL_BASE_URL}/financial-search/`,
    }

    // see https://www.gatsbyjs.org/packages/gatsby-source-filesystem/ for querying / working with gifs in gatsby
    const sectionImages = formatAdvertorialGifs(images)

    const section1Text1 = `
        <p>If you think your online searches about personal finances or credit information are only known to you, think again! Over the last few years, credit card companies and banks have been practicing search history targeting and profiling. As a consumer, it feels like someone is spying on your financial searches and using it against you.</p>

        <p>We’re now in the era of “Surveillance Capitalism” where all data is credit data. According to The Simple Dollar and FTC, consumers need to proactively safeguard their online financial search history as well as protect themselves from being targeted with manipulative content and online scams.*</p>

        <p>So what should you do?</p>

        <p>Experts from all corners of the internet have been advising that people “start searching in private!”  Thankfully, the brilliant engineers from the Netherlands have found a solution with Startpage the world’s most private search engine.</p>
    `
    const section1Text2 = `
        <p>Take the target off your back, shield your financial data, and start searching in private! Startpage is the world’s most private search engine that delivers Google search results through a proprietary anonymizing process.</p>
    `
    const section2Text1 = `
        <p>You can also view entire websites without leaving a trace or becoming a victim of financial targeting by using Startpage’s ‘Anonymous View’ feature. Fast Company says, “This alternative search engine is basically Google without the privacy headaches.”</p>
    `
    const section3Text1 = `
        <p>Stop personal finance tracking and targeting. Start with Startpage!</p>

        <p>Do you know what the best part about Startpage is? It’s completely FREE! Try it out now.</p>
    `
    const bottomLinks = `
        <ul>
            <li>
                The Simple Dollar:
                <a href='https://www.thesimpledollar.com/financial-wellness/what-is-private-browsing-and-can-it-protect-you-online/'>
                    https://www.thesimpledollar.com/financial-wellness/what-is-private-browsing-and-can-it-protect-you-online/
                </a>
            </li>
            <li>
                Federal Trade Commission:
                <a href='https://www.consumer.ftc.gov/articles/0272-how-keep-your-personal-information-secure'>
                    https://www.consumer.ftc.gov/articles/0272-how-keep-your-personal-information-secure
                </a>
            </li>
        </ul>
    `

    const contentData = {
        sections: [
            {
                title: 'Why Privacy is Important When Searching for Credit & Financial Information Online',
                image: images.hero.childImageSharp.fluid,
            },
            {
                title: 'Non-Private Search Engines Are Selling Your Search History and Digital Profile',
                text1: section1Text1,
                text2: section1Text2,
            },
            {
                title: 'How Private Search Works: No Storing Personal Financial Data, No Targeting, No Search History',
                image: sectionImages[0],
                text1: section2Text1,
            },
            {
                title: 'Startpage’s Private Browsing Feature ‘Anonymous View’',
                image: sectionImages[1],
                text1: section3Text1,
            },
            {
                text1: bottomLinks,
            },
        ],
    }
    return (
        <Layout>
            <Advertorial seo={seo} content={contentData} />
        </Layout>
    )
}

export default FinancialSearch
